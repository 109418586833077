// Path: ./src/app/desktop-src/app/pages/InfoPage/CommanContent.tsx
/**
 *
 * CommonInfoContent
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

interface Props {
  Id: string;
  title: string;
  subtitle: string;
  image: string;
  buttonText: string;
  bgColor?: string;
  rightSide?: boolean;
  Link: string;
  video?: any;
  showVideoSound?: boolean;
}

export function CommonInfoContent(props: Props) {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = React.useState(true);

  React.useEffect(() => {
    if (props.Id && props.video && videoRef.current) {
      const vid = videoRef.current;

      let debounceTimeout: NodeJS.Timeout | null = null;

      const handlePlayPause = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
          if (debounceTimeout) clearTimeout(debounceTimeout);

          debounceTimeout = setTimeout(() => {
            if (entry.isIntersecting) {
              vid.play().catch(error => {
                vid.muted = true;
                setIsMuted(true);
                vid.play();
              });
            } else {
              vid.pause();
            }
          }, 200);
        });
      };

      const observer = new IntersectionObserver(handlePlayPause, {
        root: null,
        rootMargin: '0px 0px -10% 0px',
        threshold: 0.5,
      });

      observer.observe(vid);

      return () => {
        if (debounceTimeout) clearTimeout(debounceTimeout);
        observer.unobserve(vid);
        observer.disconnect();
      };
    }
  }, [props.Id, props.video]);

  const toggleMute = () => {
    if (videoRef.current) {
      const newMutedState = !isMuted;
      videoRef.current.muted = newMutedState;
      setIsMuted(newMutedState);
      if (!videoRef.current.paused && videoRef.current.readyState >= 3) {
        videoRef.current.play().catch(error => {});
      }
    }
  };

  return (
    <TopContent bgColor={props.bgColor} data-testid="commoninfocontent-test-id">
      {props.rightSide ? (
        <Wrapper rightSide={props.rightSide}>
          <RightSide rightSide={props.rightSide}>
            <Title rightSide={props.rightSide}>
              {props.title.includes('\n')
                ? props.title
                    .split('\n')
                    .map((str, index) => <div key={index}>{str}</div>)
                : props.title}{' '}
            </Title>
            <SubTitle rightSide={props.rightSide}>
              {props.subtitle.includes('\n')
                ? props.subtitle
                    .split('\n')
                    .map((str, index) => <div key={index}>{str}</div>)
                : props.subtitle}{' '}
            </SubTitle>
            <CardButton to={props.Link}>{props.buttonText}</CardButton>
          </RightSide>
          <LeftSide>
            {props.video ? (
              <VideoWrapper>
                <CustomVideo
                  id={props.Id}
                  ref={videoRef}
                  loop
                  autoPlay
                  muted={isMuted}
                  playsInline
                  controls={false}
                >
                  <source src={props.video} type="video/mp4" />
                </CustomVideo>
                {props?.showVideoSound && (
                  <SoundButton onClick={toggleMute}>
                    {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                  </SoundButton>
                )}
              </VideoWrapper>
            ) : (
              <SideImageComponent
                src={props.image}
                rightSide={props.rightSide}
              ></SideImageComponent>
            )}
          </LeftSide>
        </Wrapper>
      ) : (
        <Wrapper rightSide={props.rightSide}>
          <LeftSide>
            {props.video ? (
              <VideoWrapper>
                <CustomVideo
                  id={props.Id}
                  ref={videoRef}
                  loop
                  autoPlay
                  muted={isMuted}
                  playsInline
                  controls={false}
                >
                  <source src={props.video} type="video/mp4" />
                </CustomVideo>
                {props?.showVideoSound && (
                  <SoundButton onClick={toggleMute}>
                    {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                  </SoundButton>
                )}
              </VideoWrapper>
            ) : (
              <SideImageComponent
                src={props.image}
                rightSide={props.rightSide}
              ></SideImageComponent>
            )}
          </LeftSide>

          <RightSide rightSide={props.rightSide}>
            <Title rightSide={props.rightSide}>
              {' '}
              {props.title.includes('\n')
                ? props.title
                    .split('\n')
                    .map((str, index) => <div key={index}>{str}</div>)
                : props.title}{' '}
            </Title>
            <SubTitle rightSide={props.rightSide}>
              {' '}
              {props.subtitle.includes('\n')
                ? props.subtitle
                    .split('\n')
                    .map((str, index) => <div key={index}>{str}</div>)
                : props.subtitle}{' '}
            </SubTitle>
            <CardButton to={props.Link}>{props.buttonText}</CardButton>
          </RightSide>
        </Wrapper>
      )}
    </TopContent>
  );
}

interface StyleProps {
  bgColor?: string;
  rightSide?: boolean;
}

const CustomVideo = styled.video`
  max-height: 700px;
  width: 100%;
  display: block;
  position: relative;
  object-fit: contain;
  @media (max-width: 471px) {
    max-width: 471px;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

const SoundButton = styled.button`
  position: absolute;
  bottom: 23px;
  left: 9px;
  background: rgba(239, 130, 0, 0.8);
  border: none;
  border-radius: 24%;
  padding: 8px;
  font-size: 10px;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  transition: background 0.3s;
  &:hover {
    background: rgba(239, 130, 0, 1);
  }
`;

const CardButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ef8200 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
  font: normal normal bold 20px Arial;
  letter-spacing: 0px;
  color: #ffffff;
  width: 60%;
  margin-top: 40px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: all 0.5s;
  @media (max-width: 1049px) {
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  @media (max-width: 707px) {
    font-size: 16px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  &:hover {
    background-color: #ffffff;
    border: 1px solid #ef8200;
    color: #ef8200;
  }
`;

const TopContent = styled.div`
  background-color: ${(props: StyleProps) => {
    return props.bgColor ? props.bgColor : '#f3ece2';
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  @media (max-width: 1400px) {
    height: 100%;
  }
`;

const Wrapper = styled.div`
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1400px) {
    margin-left: 40px;
    margin-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 1049px) {
    flex-direction: ${(props: StyleProps) => {
      return props.rightSide ? 'column' : 'column-reverse';
    }};
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media (max-width: 471px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const RightSide = styled.div`
  width: 40%;
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: ${(props: StyleProps) => {
    return props.rightSide ? 'start' : 'end';
  }};
  @media (max-width: 1049px) {
    width: 80%;
    margin-top: 60px;
    align-items: center;
  }
  @media (max-width: 471px) {
    max-width: 90%;
    align-items: center;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  @media (max-width: 1400px) {
    width: 50%;
  }
  @media (max-width: 1049px) {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 471px) {
    width: 90%;
    max-width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SideImageComponent = styled.img`
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  object-position: ${(props: StyleProps) => {
    return props.rightSide ? 'right' : 'left';
  }};
  @media (max-width: 1049px) {
    object-fit: contain;
    object-position: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const Title = styled.div`
  text-align: ${(props: StyleProps) => {
    return props.rightSide ? 'left' : 'right';
  }};
  font: normal normal bold 44px/61px Arial;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  @media (max-width: 1049px) {
    text-align: center;
  }
  @media (max-width: 707px) {
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin: auto;
  }
`;

const SubTitle = styled.div`
  text-align: ${(props: StyleProps) => {
    return props.rightSide ? 'left' : 'right';
  }};
  font: normal normal normal 24px/28px Arial;
  letter-spacing: 0px;
  color: #707070;
  margin-top: 40px;
  @media (max-width: 1049px) {
    text-align: center;
  }
  @media (max-width: 707px) {
    margin-top: 10px;
    font-size: 15px;
  }
`;
